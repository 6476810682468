.grid-ball-number {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ball {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-radius {
  border-radius: 1px 5px 5px 0px;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Upload";
  color: #1ab9eb;
  background-color: rgb(255, 255, 255);
  z-index: 2;
}
