@import url("https://js.arcgis.com/4.10/esri/css/main.css");

.sizeMap {
  /* height: 88vh,
  width: "100%",
  margin: 0,
  padding: 0, */
  height: 88vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.forMap {
  margin-top: 10vh !important;
}

.inputForm {
  position: absolute;
  left: 15px;
  top: 90px;
  right: 15px;
}

.esri-ui {
  /* position: absolute; */
  display: none;
}

.parent-provinsi-tahun {
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100vw;
  top: 110px;
  margin-right: -15px;
  margin-left: -15px;
}

.provinsi-tahun {
  width: 48vw;
  text-align: center;
}

.forLoading {
  position: fixed;
  top: 50%;
  left: 45%;
  z-index: 1000;
}

.onSumber {
  position: absolute;
  bottom: 8px;
}
