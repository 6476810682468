.forForm {
  margin-top: -10px;
  height: 50px;
}

.border-upload {
  border: 1px solid grey;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.border-upload-fill {
  border: 1px dashed #219ac0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-image {
  border: 1px solid #1ab9eb;
  border-radius: 5px;
  width: 94px;
  height: 30px;
  color: rgb(26, 185, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.border-fill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-add {
  height: 50px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #63acef;
  background: #ffffff;
  border-radius: 5px;
}

.linebold {
  border: 10px solid #b9b9b9 !important;
  border-width: 1px !important;
}

input.larger {
  width: 20px;
  height: 20px;
}
