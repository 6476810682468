.row-button-add {
  position: fixed;
  bottom: 25px;
  right: 25px;
  overflow: hidden;
}
.btn-add {
  border: none;
  width: 123px;
  color: white;
  border-radius: 5px;
  height: 39px;
}

.tab-title p {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 14px;
  margin-bottom: unset;
}

.tab-badge p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
  margin-bottom: unset;
}

.badge-grey {
  background: #ededed;
  border-radius: 4px;
}

.filter p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin-bottom: 3px;
}
