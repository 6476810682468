.parentdiv {
  position: relative;
  width: 30px;
}

.fitProfile {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
