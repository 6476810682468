.esri-ui-top-left {
  right: 0 !important;
  bottom: 0 !important;
  top: auto !important;
  left: auto !important;
}

.esri-popup__main-container {
  border-radius: 30px 30px 0 0;
}

.fast-option {
  white-space: nowrap;
  /* line-height: 30px !important; */
}
