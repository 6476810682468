body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.primary-color {
  color: #1ab9eb;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

.grid-root {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Upload";
}

/* forgot password */
.heading {
  padding-top: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}

.btn-suc {
  color: #27a771 !important;
  border-color: #ccf5eb !important;
  background: #ccf5eb !important;
}
.bg-primary {
  background-color: #1ab9eb !important;
}
.bg-secondary {
  background-color: #c4c4c4 !important;
}

.btn-err {
  color: #f43d3d !important;
  border-color: #fcddde !important;
  background: #fcddde !important;
}

/* new-password */

/* login */

.navbar-mobile {
  height: 75px;
  background: #ffffff;
  box-shadow: 0px 2px 7px rgba(149, 149, 149, 0.25);
  position: fixed;
  right: 0;
  z-index: 3;
  top: 0;
  left: 0;
  padding-left: 15px;
}

.input-group-text {
  background-color: unset !important;
  /* border-right: unset !important; */
}
.border-right-unset {
  border-right: unset !important;
}
.border-left-unset {
  border-left: unset !important;
}

/* register */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

.input-group-text {
  background-color: unset !important;
}
.btn-bg-primary {
  background: #63acef;
  border-color: #63acef !important;
}
.btn-bg-secondary {
  background: #e7f3ff !important;
  border-color: #e7f3ff !important;
}

.btn-bg-third {
  background: #ebedf0 !important;
  border-color: #ebedf0 !important;
}

.btn-bg-green {
  background: #28a745 !important;
  border-color: #28a745 !important;
}

.text-color-secondary {
  color: #63acef;
}

.text-color-black {
  color: #252733;
}

.text-with-border-bottom {
  color: #219ac0;
  text-decoration: underline;
}

.button-proses {
  border: none;
  border-radius: 15px;
  width: 80px;
  color: white;
}

.shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border: none;
}

.txt-blue {
  color: #63acef;
}
.bg-color-diproses {
  background: #ab9c17;
}

.bg-color-dikembalikan {
  background: #d9670f;
}
.bg-color-baru {
  background: #17a2b8;
}
.bg-color-selesai {
  background: #28a745;
}

.bg-color-update {
  background: #7749f8;
}
.bg-color-menunggu {
  background: #e5e5e5;
}
.form-err {
  border-color: #eb5757;
}
.fsize8 {
  font-size: 8px !important;
}
.fsize9 {
  font-size: 9px !important;
}
.fsize10 {
  font-size: 10px !important;
}
.fsize11 {
  font-size: 11px !important;
}
.fsize12 {
  font-size: 12px !important;
}
.fsize13 {
  font-size: 13px !important;
}
.fsize14 {
  font-size: 14px !important;
}
.fsize15 {
  font-size: 15px !important;
}
.fsize16 {
  font-size: 16px !important;
}
.fsize17 {
  font-size: 17px !important;
}
.fsize18 {
  font-size: 18px !important;
}
.fsize19 {
  font-size: 19px !important;
}
.fsize20 {
  font-size: 20px !important;
}
.fsize21 {
  font-size: 21px !important;
}

.bottom-underline-blue {
  border-bottom: 1px solid #17a2b8;
  transition: all ease-in-out 0.3s;
}

.bottom-underline-grey {
  border-bottom: 1px solid #e6e5e5;
  transition: all ease-in-out 0.3s;
}

.notes {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 20px;
}
