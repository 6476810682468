.linebold {
  border: 10px solid #b9b9b9 !important;
  border-width: 1px !important;
}
.parentdiv {
  position: relative;
  width: 80px !important;
  margin-bottom: 20px;
}
.square {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.fitSquare {
  height: 110%;
  width: 110%;
  object-fit: cover;
}

.editlogo {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #63acef;
  position: absolute;
  right: -10px;
  bottom: -10px;
  z-index: 1;
}
