@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-circular-progressbar/dist/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/layout";
@import "styles/pages";
//@import '~@arcgis/core/assets/esri/themes/dark/main.css';

* {
  font-family: "Roboto", sans-serif !important;
  color: #acabab;
  // font-size: 12px;
}

// html body {
//   // font-family: "Roboto", sans-serif !important;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
//   font-weight: 300;
// }

a {
  transition: all ease-in-out 0.3s;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;

  &.loading {
    width: 100px;
  }

  &.user-photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.img-user {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }

  &.vessel-photo {
    width: 360px;
  }
}
/*

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $white-three;
  border-radius: 10px;
  outline: 1px solid $white-three;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}*/
