.button-collapse {
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.title-collapse {
  font-size: 14px;
  color: #505050;
}

.paragraph p {
  margin-bottom: 0;
  font-size: 14px !important;
}

.color-underline {
  color: #2bacd4;
  text-decoration: underline;
  text-decoration-color: #2bacd4;
}
.root-title {
  padding: 0 !important;
  margin: 0;
}
.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
  padding: 0 !important;
  content: none;
}

.MuiTimelineDot-defaultGrey {
  background-color: #1ab9eb !important;
}

.circle {
  width: 30px;
  height: 30px;
  background: grey;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.circle-fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.title-timeline p {
  font-weight: bold;
  font-size: 12px !important;
}

.title-timeline span {
  font-weight: bold;
  font-size: 14px !important;
}
.child-title span {
  border-color: snow;
}

.bg-mini {
  height: 20px;
  width: 80px;
}

.child-size p {
  font-size: 12px !important;
  margin-bottom: unset;
}

.tab-left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #63acef;
  height: 43px;
  border-radius: 0 5px 0 0;
}

.tab-right {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #63acef;
  height: 43px;
  border-radius: 5px 0 0 0;
}

.h43 {
  height: 43px;
}

.list-disposisi p {
  font-size: 12px !important;
  margin-bottom: unset;
}
.file p {
  text-decoration: underline solid #219ac0;
}

.edit-listdisposisi {
  position: absolute;
  right: 0px;
  bottom: 8px;
}

.proses-disposisi {
  font-weight: bold;
  position: absolute;
  right: 0px;
  bottom: 8px;
  font-size: 12px;
  color: #63acef;
}
