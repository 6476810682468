.card-box p {
  margin-bottom: unset;
  font-size: 12px;
}

.p-txt-black p {
  color: #000000;
  margin-bottom: unset;
}

.button-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.btn-bg-primary {
  color: white;
  border-radius: 5px;
  height: 40px;
}

.btn-bg-green {
  color: white;
  border-radius: 5px;
  height: 40px;
}
